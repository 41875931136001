  /*custom fonts */

@font-face {
  font-family: Centra;
  src:url("./assets/font/CentraNo2-Bold.ttf");
 font-weight:700;
}

@font-face {
  font-family: Centra;
  src:url("./assets/font/CentraNo2-Medium.ttf");
 font-weight:500;
}

@font-face {
  font-family: Centra;
  src:url("./assets/font/CentraNo2-Book.ttf");
 font-weight:400;
}

 /* Defalut css  */
*{margin: 0;
padding:0;
box-sizing: border-box;}

html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;  
  /* kati scroll garda kati move hune bhanee */
}


body{
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212;
  color: #fff;
  font-family: 'Centra',sans-serif;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  line-height: normal;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.mainlogo{
  width: 80px;
  animation: rotation 2s infinite linear;
}
 

nav.navbar{
  background-color: rgb(32, 32, 32);
  padding : 18px 0;
  position: fixed;
  width: 100%;
 height: 80px;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled{
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav  .nav-link.navbar-link{
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  box-shadow: inset 0 0 0 0 white;

  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.me-auto{

margin-left: 40px;
}

 nav.navbar .navbar-nav a.nav-link.navbar-link:hover{
  color: black;
  box-shadow: inset 200px 0 0 0 white;;
 }

nav.navbar .navbar-nav a.nav-link.navbar-link.active{
  opacity: 1;

} 

span.navbar-text{
  display: flex;
  align-items: center;
}

.social-icon{
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
line-height: 1;
border: 1px solid rgba(255, 255, 255, 0.5);
}

 .social-icon a::before{
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
} 

 .social-icon a:hover::before{
  transform: scale(1);
} 

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) ;
}

 .vvd {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
} 

.vvd {
  z-index: 1;
}

.vvd::before{
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.vvd:hover{
  color: #121212;
}

.vvd:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute; 
}

 nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
} 

 nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before{
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
} 

 nav.navbar .navbar-toggler-icon:after{
  top: 8px;
} 
 nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after{
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
} 
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before{
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
} 


 nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon{
  border-color: transparent;
} 


/* BAnner */

.banner {
  margin-top: 0;
  padding: 180px 0 100px 0;
  background-color: #fff;
 background-image: url('./assets/img/backround.jpg'); 
background-position: top center;
background-size: cover;
background-repeat: no-repeat;
}

.banner .tagline {
  color: rgb(57, 58, 58);
  font-weight: 700;
  letter-spacing: 0.8 px;
  padding: 8px 10px ;
  background: linear-gradient(90.21deg,rgba(228, 113, 182, 0.5) -5.19%, rgba(219, 44, 228, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h2  {
  color: rgb(54, 53, 53);
   font-size: 4em; 
  font-weight: 700;
font-family:Arial, Helvetica, sans-serif;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}


.banner h3  {
  color: rgb(88, 88, 88);
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
 
.banner p {
  color: #434342;
  font-size: 18px;
  font-family:  'Times New Roman', Times, serif;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  padding: 3px 5px 3px 5px ;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

 .banner button  {
  color: #434342;
  font-size: 25px;
  margin-left: 10px;
  padding: 5px 10px 5px 10px ;
  transition: 0.3s ease-in-out;
  line-height: 1;
} 

 .banner button:hover  {
  margin-left: 25px;
  background-color: #40e5fb;
  border-color: #40e5fb;
} 

.banner img {
  animation: updown 3s linear infinite;
  width: 400px;
  /* padding-left: 100px; */
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}






/* About */

.about-whole {
  
  height: 90vh ;
  background-color: #f8f2f2;
  
}
.about-tab{
  background-color:#c9e8f7;
  border-radius: 50px;
  border: 2px solid black;
  height: 85vh;
  
  
}

.about-tab h2{
  text-align: center;
  margin: 3vh auto 5vh auto;
  font-size: 3vw;
  font-weight: 700;
  -webkit-text-stroke: 1px rgb(4, 4, 136);
}

.about_img img {
  width: 350px;
  margin: 0 0 0 10vw ;
  border: 2px dashed rgb(4, 4, 136);
  border-radius: 20px;
 
}

.about-text {
  margin-top: 10vh;
}

.about-text h3 {
  font-size:2vw;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  color: rgb(4, 4, 136);
}

.about-table {
  margin-top: 5vh;
  
}

.about-tab th {
  height: 8vh;
  width: 20vw;
  
}
.about-tab td{
padding: 0 15px;
}

.about-text-head {
  font-size: 1.5vw;
  color: rgb(4, 4, 136);
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.about-text-tail {
  font-size: 1.3vw;
  font-family: 'Times New Roman', Times, serif
}


@media screen and (max-width: 1000px) {

  .about-text {
    margin-top: 5vh;
  }

  .about_img img {
    margin: 0 0 0 5vw ;
    width: 30vw;
  }
  
  .about-tab h2{
    font-size: 4vw;
  }

  .about-text h3 {
    font-size:3vw;
  }

  .about-text-head {
    font-size: 2vw;
  }
  
  .about-text-tail {
    font-size: 1.7vw;
    font-family: 'Times New Roman', Times, serif
  }
  
}


@media screen and (max-width: 600px) {
  .about_img img {
    width: 30vw;
    margin-top: 10vh;
  }
  
  .about-tab h2{
    font-size: 4vw;
  }

  .about-text h3 {
    font-size:3.5vw;
  }

  .about-text-head {
    font-size: 3vw;
  }
  
  .about-text-tail {
    font-size: 2.4vw;
    font-family: 'Times New Roman', Times, serif
  }

  .about-img-text{
    text-align:center;
    vertical-align:middle;
  }
  
}
 








/* skills */
.skill {

padding: 50px 0 50px 0;
position: relative;
background-color: #fdfbfb;

}

.skill-bx{
  background: #c9e8f7;
  border-radius: 64px;
  text-align: center;
   padding: 60px 50px; 
  margin-top: 5px; 
} 

.skill h2{
  font-size: 45px;
  font-weight: 700;
  color: black;
}

.skill h5{
  color: #494848;
}

.skill p {
  color: #535252;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width:80%;
  margin: 0 auto;
  position : relative;
}

.skill-slider .item img {
  width:40%;
  margin: 0 auto 15px auto;
}

/* Project */

.projects{
  padding: 80px 0;
  position: relative;
  background-color: black;
  background-color: #fff;
}

.projects h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: rgb(70, 68, 68);
  padding-bottom: 40px;
}

.projects p {
  color: #3a3a3a;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 60%;
}

.projects .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(142, 141, 141, 0.1);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
} 

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #4f4e4e;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgb(103, 101, 101);
  color: rgb(157, 186, 238);
}
.nav-link#projects-tabs-tab-first {
border: 1px solid rgba(73, 72, 72, 0.5);
border-radius: 55px 0px 0px 55px;
padding-left: 45%;
}
.nav-link#projects-tabs-tab-second {
border-top: 1px solid rgba(255, 255, 255, 0.5);
border-bottom: 1px solid rgba(255, 255, 255, 0.5);
padding-left: 45%;
}
.nav-link#projects-tabs-tab-third {
border: 1px solid rgba(255, 255, 255, 0.5);
border-radius: 0 55px 55px 0;
padding-left: 40%;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 12px;
}
.projectcards{
  margin-top: 50px;
}

.proj-imgbx-txtx {
  border-radius: 10% ;
background-color: rgb(196, 211, 215);
}


.proj-imgbx img {
 width: 100%;
 padding: 2px auto 2px auto;
}

.proj-txtx{
  margin-left: 30px;
}

.proj-txtx h4{
  
  color: rgb(53, 53, 53);
  margin-bottom: -15px;
  width: 60%;
}
.proj-txtx span {
  color: rgb(53, 53, 53);
  font-size: 16px;
  font-weight: 450;
  width: 90%;
}

.proj-txtx p{
  
  width: 60%;
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin-top: 0;
  text-align: left;
    margin-bottom: 20px;
    padding-bottom: 20px ;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: normal;
    
}

.datascience{
  background-color: rgb(196, 211, 215);
  border-radius: 5px;
  padding: 5px 10px 5px 10px ;
  font-size: 18px;
  font-weight: 450;
  max-width: max-content;
 margin: 0px auto 0px auto;

}

@import url("https://fonts.googleapis.com/css?family=Gelasio&display=swap");


.bar {
  position: absolute;
  width: 100%;
  padding: 25px 0;
  font-family: Gelasio;
  font-size: 18px;
}

.bar_content {
  display: block; 
  width: 100%;
  transform: translateX(100%); 
  animation: move 8s linear infinite ;
}


@keyframes move {
  to { transform: translateX(-100%); }
}









/*contact */

.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}

.contact h2 {
  color: #fff;
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}


.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}


.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}















/* Footer */
.footer {
  
  height: 11vh;
  padding: 0 0 0 0;
  background-image: url('./assets/img//footer-bg.png');
  background-position: center center;
  background-size : cover;
  background-repeat: no-repeat;

}


.footer img {
   margin-top: 5px;
 width: 75px;
}

.footer p {
  margin-top: -50px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
}

.footer .social-icon {
  margin-top: 20px;
}






.sidebar{
  position: fixed;
  right:-250px;
  top: 100px;
  width:250px;
  height:100%;
  background-color: rgb(0, 0, 0);
  transition: all .5s ease;
}

.sidebar header{
  font-size: 22px;
  background-color: #000;
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 70px;
  
  user-select: none;
}
.sidebar li {
  height: 50px;
}



.sidebar ul a{
  display: block;
  height:70%;
  width: 100%;
  line-height: 30px;
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding-left: 40px;
  box-sizing: border-box;
  border-top: 1px solid rgb(255,255, 255,.1);
  border-bottom: 1px solid black;
  transition: .4s;
  text-decoration: none;
}

.sidebar ul a:hover {
background-color: rgb(255, 255, 255);
}

ul li:hover a{
  padding-left: 50px;

}

.sidebar ul a i{
  margin-right: 16px;
}

#check{
  display: none;
}

label #btn,label #cancel {
 
  position: absolute;
  cursor: pointer;
  background:rgba(219, 44, 228, 0.5);
  border-radius: 3px;
}

label #btn{
  /* visibility: hidden; */
  right: 15px;
  top: 30px;
  font-size: 35px;
  color: rgb(255, 255, 255);
  padding: 6px 12px ;
  transition: all .5s;
}

label #cancel {
  
  display: none;
  z-index: 1111;
  right: 195px;
  top: 117px;
  font-size: 30px;
  color: #ffffff;
  padding: 4px 9px;
  transition: all .5s ease;
}

#check:checked ~ .sidebar{
  right: 0;
}

#check:checked ~ label #btn{
  
  right: 250px;
  opacity:0;
  pointer-events: none;
}

#check:checked ~ label #cancel{
  display: block;
  right: 195px;
}



















@media screen and (max-width: 1000px) {
 .banner h2 {
  font-size: 40px;
    
  }

  .banner h3 {
    font-size: 45px;
  }

  .banner p {
    font-size: 15px;
  }
   .banner img {
    width: 300px;
   }

   .banner button {
    font-size: 16px;
   
    margin-top: 40px;
   }
}


@media screen and (max-width: 770px) {
  .banner h2 {
   font-size: 30px;
     
   }
 
   .banner h3 {
     font-size: 35px;
   }
 
   .banner p {
     font-size: 8px;
   }
    .banner img {
     width: 200px;
    }
 
    .banner button {
     font-size: 14px;
    
     margin-top: 30px;
    }
 }


 @media screen and (max-width: 1000px) {
.social-icon  {
  visibility: hidden;
}
 .vvd  {
  visibility: hidden;
}
 .me-auto {
  visibility: hidden;
}

 }

  @media screen and (min-width: 1000px) {

  .sidebarmenu{
    visibility: hidden;
  }
 } 


/* for experience */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.wrapper{
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line{
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row{
  display: flex;
}
.wrapper .row-1{
  justify-content: flex-start;
}
.wrapper .row-2{
  justify-content: flex-end;
}
 .wrapper .row section{
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
} 
 .wrapper .row section::before{
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
} 
.wrapper .row-1 section::before{
  right: -7px;
}
.wrapper .row-2 section::before{
  left: -7px;
}
 .wrapper .row section .icon,
.wrapper .center-line .scroll-icon{
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #3ea0e2;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
} 
.wrapper .center-line .scroll-icon{
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.wrapper .row-1 section .icon{
  top: 15px;
  right: -60px;
}
.wrapper .row-2 section .icon{
  top: 15px;
  left: -60px;
}
.wrapper .row section .details,
.wrapper .row section .bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .row section .details .title{
  font-size: 22px;
  font-weight: 600;
}
.wrapper .row section p{
  margin: 10px 0 17px 0;
}

.wrapper .row section .bottom a{
  text-decoration: none;
  background: #3ea0e2;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
} 

.wrapper .row section .bottom a:hover{
  transform: scale(0.97);
}
@media(max-width: 790px){
  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section{
    width: 100%;
  }
  .wrapper .row-1 section::before{
    left: -7px;
  }
  .wrapper .row-1 section .icon{
    left: -60px;
  }
}
@media(max-width: 440px){
  .wrapper .center-line,
  .wrapper .row section::before,
  .wrapper .row section .icon{
    display: none;
  }
  .wrapper .row{
    margin: 10px 0;
  }
}